import React from "react";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";

const NotFoundPage = () => (
  <Layout>
    <PageHeader>
      404
    </PageHeader>
    <div id="content">
      Not found!
    </div>
  </Layout>
);

export default NotFoundPage;